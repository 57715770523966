<template>
  <div>
    <BaseTable
      :columns="columns"
      :resource="$data.$constants.RESOURCES.RESOURCE_SALES_ORDERS_REPORT"
      :loading="loading"
      :hideTotals="true"
      :hideSearchButton="true"
      :hidePrinterButton="true"
      :hideFilterButton="true"
      :hideImportButton="true"
      :disablePagination="true"
      :resourceStore="'salesOrdersReports'"
    >
      <template #cell(total_sale)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(additional_lines)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(total)="data">
        {{ data.value | numberToLocalString }} €
      </template>
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from "@/components/ui/table/BaseTable.vue";

export default {
  name: 'FinancialLimitReportsTable',
  components: {
    BaseTable,
  },
  data() {
    return {
      loading: false,
      columns: [
        { 
          label: "DIVISION",
          key: "division",
          sortable: false,
        },
        {
          label: "Nº JOBS",
          key: "number_jobs",
          sortable: false,
        },
        { 
          label: "NUEVOS PEDIDOS AÑO EN CURSO",
          key: "total_sale",
          sortable: false,
        },
        { 
          label: "AMPLIACION NO AÑO EN CURSO",
          key: "additional_lines",
          sortable: false,
        },
        { 
          label: "TOTAL",
          key: "total",
          sortable: false,
        },      
      ]
    }
  },
}

</script>