<template>
  <BaseCard
   :footer-visible="false"
   :caption-visible="false"
 >
   <template #title>
     <span class="h3 font-weight-bold mb-0 text-uppercase">
        Informe de pedidos/ventas
     </span>
   </template>
   <SalesOrdersReportsTable />
 </BaseCard>
</template>


<script>
import SalesOrdersReportsTable from '@/components/sales-orders-reports/table/SalesOrdersReportsTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
 components: { BaseCard, SalesOrdersReportsTable },
}
</script>
